<template>
  <div>
    <div
      class="modal fade"
      id="modal-escaner-documento"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Replicas</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal"
              @click="clearDraftDocuments()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="card-body">
            <!-- datos del viaje  -->
            <div class="row">
              <div class="col-md-12 col-sm-12 col-xs-12">
                <fieldset class="well-card rounded border border-primary">
                  <legend
                    class="well-legend-rounded text-bold bg-frontera text-light text-center small-text"
                  >
                    Informacion General
                    <button
                      @click="toggleCollapse"
                      class="btn-toggle small-button"
                    >
                      {{ isCollapsed ? "+" : "-" }}
                    </button>
                  </legend>

                  <div v-show="!isCollapsed">
                    <div v-if="diferencia_guia_notificacione_cobro_id">
                      <div class="card-body text-muted text-s">
                        <div class="row justify-content-center">
                          <div class="col mb-3">
                            <ul class="fa-ul mb-0">
                              <li>
                                <div class="row">
                                  <div class="col-md-4">ID:</div>
                                  <div class="col-md-6 mb-1">
                                    <strong>{{
                                      diferencia_guia_notificacione_cobro_id
                                    }}</strong>
                                  </div>
                                </div>

                                <div class="row">
                                  <div class="col-md-4">Guia:</div>
                                  <div class="col-md-6 mb-1">
                                    <strong>{{
                                      datos.viaje
                                        ? datos.viaje.numero_guia
                                        : " "
                                    }}</strong>
                                  </div>
                                </div>

                                <div class="row">
                                  <div class="col-md-4">Numero de Viaje:</div>
                                  <div class="col-md-6 mb-1">
                                    <strong>{{ datos.viaje_id }}</strong>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>

                          <div class="col mb-5">
                            <ul class="fa-ul mb-0">
                              <li>
                                <div class="row">
                                  <div class="col-md-4">Vehiculo:</div>
                                  <div class="col-md-6 mb-1">
                                    <strong>{{
                                      datos.viaje.vehiculo.placa
                                    }}</strong>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-4">Remolque:</div>
                                  <div class="col-md-6 mb-1">
                                    <strong>{{
                                      datos.viaje.remolque.placa
                                    }}</strong>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-4">Transportadora:</div>
                                  <div class="col-md-8 mb-1">
                                    <strong>{{
                                      datos.viaje.transportadora.razon_social
                                    }}</strong>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-4">Conductor:</div>
                                  <div class="col-md-7 mb-1">
                                    <strong
                                      >{{ datos.viaje.conductor.nombres }}
                                      {{
                                        datos.viaje.conductor.apellidos
                                      }}</strong
                                    >
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>

                          <div class="col mb-3">
                            <ul class="fa-ul mb-0">
                              <li>
                                <div class="row">
                                  <div class="col-md-4">NSV Cargue:</div>
                                  <div class="col-md-6 mb-1">
                                    <strong>{{ datos.nsv_cargue }}</strong>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-4">NSV Descargue:</div>
                                  <div class="col-md-6 mb-1">
                                    <strong>{{ datos.nsv_descargue }}</strong>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-4">NSV diferencia:</div>
                                  <div class="col-md-6 mb-1">
                                    <strong>{{ datos.nsv_diferencia }}</strong>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>

                          <!--                             <div
                              class="col-md-3 text-center"
                              v-if="datos.cantidad_envios >= 1"
                            >
                              <strong>Numero de Replica </strong>
                              <div class="text-center"></div>
                              <div
                                class="text-center"
                                v-if="datos.cantidad_envios >= 1"
                              >
                                {{ datos.cantidad_envios }}
                              </div>
                            </div> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>

            <div class="row">
              <!-- Cargue de Documentos-->
              <div
                class="col-md-6 col-sm-12 col-xs-12"
                v-if="
                  (datos.cantidad_envios <= 1 &&
                    [2, 6].includes(datos.estado) &&
                    $store.getters.can(
                      'hidrocarburos.faltantes.subirDocuemtos'
                    )) ||
                    (datos.intento == 1 &&
                      datos.cantidad_envios <= 2 &&
                      [2, 6].includes(datos.estado) &&
                      $store.getters.can(
                        'hidrocarburos.faltantes.subirDocuemtos'
                      ))
                "
              >
                <fieldset class="well-card rounded border border-primary">
                  <legend
                    class="well-legend-rounded text-bold bg-frontera text-light text-center small-text"
                  >
                    Cargue de Documentos
                    <button
                      @click="toggleCollapse2"
                      class="btn-toggle small-button"
                    >
                      {{ isCollapsed2 ? "+" : "-" }}
                    </button>
                  </legend>

                  <div v-show="!isCollapsed2">
                    <div class="modal-body">
                      <div class="form-group">
                        <label for="fileInput">Seleccionar Documento</label>
                        <div class="input-group">
                          <span
                            class="btn btn-success btn-sm fileinput-button dz-clickable text-center"
                            @click="triggerFileInput"
                          >
                            <i class="fas fa-plus"></i>
                            <span> Agregar Adjunto</span>
                            <input
                              type="file"
                              class="form-control-file"
                              id="fileInput"
                              accept=".pdf, .PDF, .xlsx, .JPG, .PNG, .docx"
                              ref="fileInput"
                              @change="handleFileChange"
                              style="display: none;"
                            />
                          </span>
                          <input
                            type="text"
                            class="form-control"
                            v-model="selectedFileName"
                            placeholder="No se ha seleccionado algun archivo"
                            readonly
                            style="pointer-events: none;"
                          />
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="tipoSoporteSelect">Tipo de Soporte</label>
                        <select
                          class="form-control"
                          id="tipoSoporteSelect"
                          v-model="tipoSoporte"
                          required
                        >
                          <option value="1">ANALISIS GPS</option>
                          <option value="2">REPORTE GPS</option>
                          <option value="3">FORMATO R-TC-TT-054</option>
                          <option value="4">OTRO</option>
                        </select>
                      </div>
                      <div class="form-group">
                        <label for="descripcionTextarea">Descripción</label>
                        <textarea
                          class="form-control"
                          id="descripcionTextarea"
                          rows="3"
                          v-model="descripcion"
                        ></textarea>
                      </div>
                      <button
                        type="button"
                        class="btn btn-lg btn-success"
                        @click="addDraftDocument"
                      >
                        Añadir a Borradores
                      </button>
                    </div>
                  </div>
                </fieldset>
              </div>

              <!-- Vista Documentos -->

              <div
                class="col-md-6 col-sm-12 col-xs-12"
                v-if="documentos != 0 || draftDocuments != 0"
                v-show="
                  $store.getters.can('hidrocarburos.faltantes.vistaDocumentos')
                "
              >
                <fieldset class="well-card rounded border border-primary">
                  <legend
                    class="well-legend-rounded text-bold bg-frontera text-light text-center small-text"
                  >
                    Documentos
                    <button
                      @click="toggleCollapse3"
                      class="btn-toggle small-button"
                    >
                      {{ isCollapsed3 ? "+" : "-" }}
                    </button>
                  </legend>

                  <div class="card-body" v-show="!isCollapsed3">
                    <div class="row justify-content-center">
                      <div
                        class="col-md-3"
                        v-for="(documento, index) in allDocuments"
                        :key="documento.key"
                      >
                        <div class="card">
                          <div
                            class="card-footer text-right p-1"
                            v-if="
                              documento.estado === 'Borrador' ||
                                (datos.cantidad_envios <= 3 &&
                                  datos.estado == 2 &&
                                  documento.estado !== 'Borrador')
                            "
                          >
                            <div class="btn-group">
                              <button
                                type="button"
                                class="btn btn-danger btn-sm"
                                v-if="documento.estado === 'Borrador'"
                                @click="removeDraftDocument(index)"
                              >
                                <i class="fas fa-trash"></i>
                              </button>
                              <button
                                type="button"
                                class="btn btn-danger btn-sm"
                                v-if="
                                  datos.cantidad_envios <= 3 &&
                                    datos.estado == 2 &&
                                    documento.estado !== 'Borrador'
                                "
                                @click="removeUploadedDocument(documento)"
                              >
                                <i class="fas fa-trash"></i>
                              </button>
                            </div>
                          </div>
                          <div class="card-body p-1">
                            <div class="row justfy-content-between">
                              <div class="col-4">
                                <button
                                  type="button"
                                  class="btn btn-success btn-sm"
                                  v-if="
                                    documento.estado !== 'Borrador' &&
                                      (documento.file_path || documento.link)
                                  "
                                  @click="downloadDocumento(documento)"
                                >
                                  <i class="fas fa-download"></i>
                                </button>
                              </div>
                              <div class="col-8 text-center text-xs text-muted">
                                <span>{{
                                  tipoSoporteTexto(documento.tipo_soporte)
                                }}</span>
                              </div>
                              <div class="col-12 text-xs text-muted">
                                <small>{{ documento.descripcion }}</small>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="modal-footer p-0"
                      v-if="
                        datos.cantidad_envios <= 3 &&
                          datos.estado == 2 &&
                          draftDocuments != 0
                      "
                    >
                      <button
                        type="button"
                        class="btn btn-primary"
                        @click="saveAllDocuments"
                      >
                        Guardar Documentos
                      </button>
                    </div>
                    <div
                      class="modal-footer"
                      v-if="
                        (datos.cantidad_envios <= 1 &&
                          [2, 6].includes(datos.estado) &&
                          documentos != 0) ||
                          (datos.intento == 1 &&
                            datos.cantidad_envios <= 2 &&
                            [2, 6].includes(datos.estado) &&
                            documentos != 0)
                      "
                    >
                      <button
                        v-if="draftDocuments != 0"
                        type="button"
                        class="btn btn-success"
                        @click="saveAllDocuments"
                      >
                        Guardar Documentos
                      </button>
                      <button
                        type="button"
                        class="btn btn-primary"
                        @click="enviarJustificacion"
                      >
                        Enviar a Justificar
                      </button>
                    </div>
                  </div>
                </fieldset>
              </div>

              <!-- Justificacion Seguridad Fisica-->

              <div
                class="col-md-6 col-sm-12 col-xs-12"
                v-if="
                  datos.estado === 4 &&
                    datos.diferencias_guia_areas.some(
                      (ar) => ar.estado === 1 && ar.area === '1'
                    ) &&
                    $store.getters.can(
                      'hidrocarburos.faltantes.justificacionSeguridadFisica'
                    ) &&
                    datos.cantidad_envios >= 1 &&
                    datos.cantidad_envios <= 2
                "
              >
                <fieldset class="well-card rounded border border-primary">
                  <legend
                    class="well-legend-rounded text-bold bg-frontera text-light text-center small-text"
                  >
                    Justificacion Seguridad Fisica
                    <button
                      @click="toggleCollapse5"
                      class="btn-toggle small-button"
                    >
                      {{ isCollapsed5 ? "+" : "-" }}
                    </button>
                  </legend>
                  <div v-show="!isCollapsed5">
                    <div class="d-flex flex-column align-items-center">
                      <div class="form-group col-md-11">
                        <label for="CampoTextoSeguridad">Justificación</label>
                        <textarea
                          class="form-control"
                          id="CampoTextoSeguridad"
                          rows="3"
                          v-model="CampoTextoSeguridad"
                        ></textarea>
                      </div>
                      <!-- Cambio de estado -->
                      <div class="form-group col-md-11">
                        <label>Estado</label>
                        <select v-model="estadoSeguridad" class="form-control">
                          <option value="">Seleccione...</option>
                          <option
                            v-for="estado in listasForms.estados"
                            :key="estado.numeracion"
                            :value="estado.numeracion"
                          >
                            {{ estado.descripcion }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button
                        v-if="
                          datos.estado === 4 &&
                            estadoSeguridad != null &&
                            estadoSeguridad != '' &&
                            CampoTextoSeguridad != ''
                        "
                        type="button"
                        class="btn btn-primary"
                        @click="enviarRespuestaSeguridad()"
                      >
                        Enviar respuesta
                      </button>
                    </div>
                  </div>
                </fieldset>
              </div>

              <!-- Justificacion Medicion-->
              <div
                class="col-md-12 col-sm-12 col-xs-12"
                v-if="
                  datos.estado === 4 &&
                    datos.diferencias_guia_areas.some(
                      (ar) => ar.estado === 1 && ar.area === '3'
                    ) &&
                    $store.getters.can(
                      'hidrocarburos.faltantes.justificacionMediciones'
                    )
                "
              >
                <fieldset class="well-card rounded border border-primary">
                  <legend
                    class="well-legend-rounded text-bold bg-frontera text-light text-center small-text"
                  >
                    Justificacion Mediciones
                    <button
                      @click="toggleCollapse7()"
                      class="btn-toggle small-button"
                    >
                      {{ isCollapsed7 ? "+" : "-" }}
                    </button>
                  </legend>

                  <div v-show="!isCollapsed7">
                    <div class="modal-body">
                      <div class="row">
                        <div class="col-md-6 col-sm-12 col-xs-12">
                          <div class="form-group col-md-11">
                            <label for="CampoTextoMedicion"
                              >Justificación</label
                            >
                            <textarea
                              class="form-control"
                              id="CampoTextoMedicion"
                              rows="3"
                              v-model="CampoTextoMedicion"
                            ></textarea>
                          </div>
                          <!-- Cambio de estado -->
                          <div class="form-group col-md-11">
                            <label>Estado</label>
                            <select
                              v-model="estadoMedicion"
                              class="form-control"
                            >
                              <option value="">Seleccione...</option>
                              <option
                                v-for="estado in listasForms.estados"
                                :key="estado.numeracion"
                                :value="estado.numeracion"
                              >
                                {{ estado.descripcion }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-12 col-xs-12">
                          <table
                            class="class=table table-bordered table-striped table-hover table-sm"
                            v-for="infoGuia in datos.viaje.guia.det_guias"
                            :key="infoGuia.id"
                          >
                            <tbody
                              v-for="cierreGuia in datos.viaje.guia
                                .cierre_guias"
                              :key="cierreGuia.id"
                            >
                              <td
                                rowspan="6"
                                class="bg-frontera-top-left pt-2 pb-2 text-white text-center"
                                style="text-align: center; vertical-align: middle;"
                              >
                                {{ infoGuia.producto_liquido.nombre }}
                              </td>
                              <td
                                class="bg-frontera-top-left pt-2 pb-2 text-white text-center"
                              ></td>
                              <td
                                class="bg-frontera-top-left pt-2 pb-2 text-white text-center"
                              >
                                <strong>Cargue</strong>
                              </td>
                              <td
                                class="bg-frontera-top-left pt-2 pb-2 text-white text-center"
                              >
                                <strong>Descargue</strong>
                              </td>
                              <td
                                class="bg-frontera-top-left pt-2 pb-2 text-white text-center"
                              >
                                <strong>Porcentaje</strong>
                              </td>
                              <td
                                class="bg-frontera-top-left pt-2 pb-2 text-white text-center"
                              >
                                <strong>Diferencia</strong>
                              </td>

                              <tr>
                                <td>API:</td>
                                <td class="text-center">
                                  {{ infoGuia.api }}
                                </td>
                                <td>
                                  {{ cierreGuia.api }}
                                </td>
                                <td>
                                  {{ cierreGuia.diferencia_api
                                  }}<small>(%)</small>
                                </td>
                                <td>
                                  {{
                                    (cierreGuia.api - infoGuia.api).toFixed(2)
                                  }}
                                </td>
                              </tr>
                              <tr
                                v-for="cierreGuia in datos.viaje.guia
                                  .cierre_guias"
                                :key="cierreGuia.id"
                              >
                                <td>Vol. GOV:</td>
                                <td class="text-center">
                                  {{ infoGuia.gov }}
                                </td>
                                <td>
                                  {{ cierreGuia.barriles_gov }}
                                </td>
                                <td>
                                  {{ cierreGuia.diferencia_gov
                                  }}<small>(%)</small>
                                </td>
                                <td>
                                  {{
                                    (
                                      cierreGuia.barriles_gov - infoGuia.gov
                                    ).toFixed(2)
                                  }}
                                </td>
                              </tr>
                              <tr>
                                <td>Vol. GSV:</td>
                                <td class="text-center">
                                  {{ infoGuia.gsv }}
                                </td>
                                <td>
                                  {{ cierreGuia.barriles_gsv }}
                                </td>
                                <td>
                                  {{ cierreGuia.diferencia_gsv
                                  }}<small>(%)</small>
                                </td>
                                <td>
                                  {{
                                    (
                                      cierreGuia.barriles_gsv - infoGuia.gsv
                                    ).toFixed(2)
                                  }}
                                </td>
                              </tr>
                              <tr>
                                <td>Vol. NSV:</td>
                                <td class="text-center">
                                  {{ infoGuia.nsv }}
                                </td>
                                <td>
                                  {{ cierreGuia.barriles_nsv }}
                                </td>
                                <td>
                                  {{ cierreGuia.diferencia_nsv
                                  }}<small>(%)</small>
                                </td>
                                <td>
                                  {{
                                    (
                                      cierreGuia.barriles_nsv - infoGuia.nsv
                                    ).toFixed(2)
                                  }}
                                </td>
                              </tr>
                              <tr>
                                <td>BSW:</td>
                                <td class="text-center">
                                  {{ infoGuia.bsw }}
                                </td>
                                <td>
                                  {{ cierreGuia.porcentaje_bsw }}
                                </td>
                                <td>
                                  {{ cierreGuia.diferencia_bsw
                                  }}<small>(%)</small>
                                </td>
                                <td>
                                  {{
                                    (
                                      cierreGuia.porcentaje_bsw - infoGuia.bsw
                                    ).toFixed(2)
                                  }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div
                      class="modal-footer"
                      v-if="
                        this.CampoTextoMedicion != '' &&
                          this.estadoMedicion != null &&
                          this.estadoMedicion != ''
                      "
                    >
                      <button
                        v-if="datos.estado === 4"
                        type="button"
                        class="btn btn-primary"
                        @click="enviarRespuestaMedicion()"
                      >
                        Enviar respuesta
                      </button>
                    </div>
                  </div>
                </fieldset>
              </div>

              <!-- Justificacion Trasnporte-->
              <div
                class="col-md-6 col-sm-12 col-xs-12"
                v-if="
                  datos.estado === 4 &&
                    datos.diferencias_guia_areas.some(
                      (ar) => ar.estado === 1 && ar.area === '2'
                    ) &&
                    $store.getters.can(
                      'hidrocarburos.faltantes.justificacionTransporte'
                    )
                "
              >
                <fieldset class="well-card rounded border border-primary">
                  <legend
                    class="well-legend-rounded text-bold bg-frontera text-light text-center small-text"
                  >
                    Justificacion Transporte
                    <button
                      @click="toggleCollapse8"
                      class="btn-toggle small-button"
                    >
                      {{ isCollapsed8 ? "+" : "-" }}
                    </button>
                  </legend>

                  <div v-show="!isCollapsed8">
                    <div class="d-flex flex-column align-items-center">
                      <div class="form-group col-md-11">
                        <label for="CampoTextoTransporte">Justificación</label>
                        <textarea
                          class="form-control"
                          id="CampoTextoTransporte"
                          rows="3"
                          v-model="CampoTextoTransporte"
                        ></textarea>
                      </div>
                      <!-- Cambio de estado -->
                      <div class="form-group col-md-11">
                        <label>Estado</label>
                        <select v-model="estadoTransporte" class="form-control">
                          <option value="">Seleccione...</option>
                          <option
                            v-for="estado in listasForms.estados"
                            :key="estado.numeracion"
                            :value="estado.numeracion"
                          >
                            {{ estado.descripcion }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button
                        v-if="
                          datos.estado === 4 &&
                            CampoTextoTransporte != '' &&
                            estadoTransporte != null &&
                            estadoTransporte != ''
                        "
                        type="button"
                        class="btn btn-primary"
                        @click="enviarRespuestaTransporte()"
                      >
                        Enviar respuesta
                      </button>
                    </div>
                  </div>
                </fieldset>
              </div>
              <!-- Asignacion de areas -->
              <div
                class="col-md-6 col-sm-12 col-xs-12"
                v-if="
                  datos.estado === 3 &&
                    $store.getters.can('hidrocarburos.faltantes.asignarArea') &&
                    datos.cantidad_envios >= 1 &&
                    datos.cantidad_envios <= 2
                "
              >
                <fieldset class="well-card rounded border border-primary">
                  <legend
                    class="well-legend-rounded text-bold bg-frontera text-light text-center small-text"
                  >
                    Area Encargada
                    <button
                      @click="toggleCollapse4"
                      class="btn-toggle small-button"
                    >
                      {{ isCollapsed4 ? "+" : "-" }}
                    </button>
                  </legend>

                  <div v-show="!isCollapsed4">
                    <div class="form-group col-md-10 ">
                      <label>Área de aceptación</label>
                      <v-select
                        v-model="areaSeleccionada"
                        class="form-control form-control-sm p-0"
                        placeholder="Nombre"
                        label="descripcion"
                        multiple
                        :options="listasForms.areas"
                      ></v-select>
                    </div>
                    <div class="modal-footer">
                      <button
                        v-if="datos.estado === 3 && areaSeleccionada != 0"
                        type="button"
                        class="btn btn-primary"
                        @click="enviarAreaEncargada()"
                      >
                        Enviar al area encargada
                      </button>
                    </div>
                  </div>
                </fieldset>
              </div>

              <!-- Asignar administrador de contrato -->
              <div
                class="col-md-12 col-sm-12 col-xs-12"
                v-if="
                  datos.estado === 3 &&
                    $store.getters.can('hidrocarburos.faltantes.asignarArea') &&
                    datos.cantidad_envios === 3
                "
              >
                <fieldset class="well-card rounded border border-primary">
                  <legend
                    class="well-legend-rounded text-bold bg-frontera text-light text-center small-text"
                  >
                    Asignar Administrador Contrato
                    <button
                      @click="toggleCollapse4"
                      class="btn-toggle small-button"
                    >
                      {{ isCollapsed10 ? "+" : "-" }}
                    </button>
                  </legend>

                  <div v-show="!isCollapsed10" class="card-body text-s">
                    <div class="row justify-content-center">
                      <div class="form-group col-md-6 ">
                        <label>Funcionarios</label>
                        <v-select
                          v-model="funcionarioSeleccionada"
                          class="form-control form-control-sm p-0"
                          placeholder="Nombre"
                          label="funcionario"
                          multiple
                          :options="$parent.listasForms.funcionarios"
                        ></v-select>
                      </div>
                      <div class="form-group col-md-6 ">
                        <table
                          class="class=table table-bordered table-striped table-hover table-sm"
                          v-if="funcionarioSeleccionada != 0"
                        >
                          <tr>
                            <th>
                              Funcionario
                            </th>
                            <th>
                              Correo
                            </th>
                          </tr>
                          <tbody>
                            <tr
                              v-for="funcionarioSeleccionada in funcionarioSeleccionada"
                              :key="funcionarioSeleccionada.id"
                            >
                              <td>
                                {{ funcionarioSeleccionada.funcionario }}
                              </td>
                              <td>
                                {{ funcionarioSeleccionada.email }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button
                        v-if="
                          datos.estado === 3 && funcionarioSeleccionada != 0
                        "
                        type="button"
                        class="btn btn-primary"
                        @click="enviarAdminContrato()"
                      >
                        Enviar Correo
                      </button>
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>

            <!-- Confirmador admin Contratos -->

            <div
              class="row"
              v-if="
                datos.estado == 9 &&
                  $store.getters.can('hidrocarburos.faltantes.adminContratos')
              "
            >
              <div class="col-md-12 col-sm-12 col-xs-12">
                <fieldset class="well-card rounded border border-primary">
                  <legend
                    class="well-legend-rounded text-bold bg-frontera text-light text-center small-text"
                  >
                    Confirmador
                    <button
                      @click="toggleCollapse9"
                      class="btn-toggle small-button"
                    >
                      {{ isCollapsed9 ? "+" : "-" }}
                    </button>
                  </legend>
                  <div class="tab-pane" v-show="!isCollapsed9">
                    <div class="modal-body">
                      <div class="row">
                        <div class="col-md-6 col-sm-12 col-xs-12">
                          <label>Estado</label>
                          <select
                            v-model="estadoConfirmador"
                            class="form-control"
                          >
                            <option value="">Seleccione...</option>
                            <option
                              v-for="estado in listasForms.estados"
                              :key="estado.numeracion"
                              :value="estado.numeracion"
                            >
                              {{ estado.descripcion }}
                            </option>
                          </select>

                          <label for="CampoTextoConfirmador"
                            >Justificación</label
                          >
                          <textarea
                            class="form-control"
                            id="CampoTextoConfirmador"
                            rows="3"
                            v-model="CampoTextoConfirmador"
                          ></textarea>
                        </div>

                        <div class="col-md-6 col-sm-12 col-xs-12">
                          <table
                            class="table table-bordered table-hover text-nowrap table-sm "
                          >
                            <thead class="bg-dark">
                              <tr>
                                <th>Area</th>
                                <th>Usuraio</th>
                                <th>N° Replica</th>
                                <th>Justificacion</th>
                                <th>Estado</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="item in datos.diferencias_guia_areas"
                                :key="item.id"
                              >
                                <td>{{ item.Narea }}</td>
                                <td>
                                  {{ item.usuario ? item.usuario.name : "" }}
                                </td>
                                <td class="text-center">
                                  {{ item.intento }}
                                </td>
                                <td>{{ item.respuesta }}</td>
                                <td>
                                  {{
                                    item.estado === 1
                                      ? "Pendiente"
                                      : item.estado === 2
                                      ? "Cobrado"
                                      : item.estado === 3
                                      ? "No Cobrado"
                                      : ""
                                  }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button
                        v-if="datos.estado === 9 && estadoConfirmador != null"
                        type="button"
                        class="btn btn-primary"
                        @click="enviarRespuestaConfirmador()"
                      >
                        Enviar respuesta
                      </button>
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>

            <!-- Confirmador -->

            <div
              class="row"
              v-if="
                datos.confirmador == 1 &&
                  datos.estado == 7 &&
                  $store.getters.can('hidrocarburos.faltantes.confirmador')
              "
            >
              <div class="col-md-12 col-sm-12 col-xs-12">
                <fieldset class="well-card rounded border border-primary">
                  <legend
                    class="well-legend-rounded text-bold bg-frontera text-light text-center small-text"
                  >
                    Confirmador
                    <button
                      @click="toggleCollapse9"
                      class="btn-toggle small-button"
                    >
                      {{ isCollapsed9 ? "+" : "-" }}
                    </button>
                  </legend>
                  <div class="tab-pane" v-show="!isCollapsed9">
                    <div class="modal-body">
                      <div class="row">
                        <div class="col-md-6 col-sm-12 col-xs-12">
                          <label>Estado</label>
                          <select
                            v-model="estadoConfirmador"
                            class="form-control"
                          >
                            <option value="">Seleccione...</option>
                            <option
                              v-for="estado in listasForms.estados"
                              :key="estado.numeracion"
                              :value="estado.numeracion"
                            >
                              {{ estado.descripcion }}
                            </option>
                          </select>

                          <label for="CampoTextoConfirmador"
                            >Justificación</label
                          >
                          <textarea
                            class="form-control"
                            id="CampoTextoConfirmador"
                            rows="3"
                            v-model="CampoTextoConfirmador"
                          ></textarea>
                        </div>

                        <div class="col-md-6 col-sm-12 col-xs-12">
                          <table
                            class="table table-bordered table-hover text-nowrap table-sm "
                          >
                            <thead class="bg-dark">
                              <tr>
                                <th>Area</th>
                                <th>Usuraio</th>
                                <th>N° Replica</th>
                                <th>Justificacion</th>
                                <th>Estado</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="item in datos.diferencias_guia_areas"
                                :key="item.id"
                              >
                                <td>{{ item.Narea }}</td>
                                <td>
                                  {{ item.usuario ? item.usuario.name : "" }}
                                </td>
                                <td class="text-center">
                                  {{ item.intento }}
                                </td>
                                <td>{{ item.respuesta }}</td>
                                <td>
                                  {{
                                    item.estado === 1
                                      ? "Pendiente"
                                      : item.estado === 2
                                      ? "Cobrado"
                                      : item.estado === 3
                                      ? "No Cobrado"
                                      : ""
                                  }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button
                        v-if="
                          datos.estado === 7 &&
                            CampoTextoConfirmador != '' &&
                            estadoConfirmador != null &&
                            estadoConfirmador != ''
                        "
                        type="button"
                        class="btn btn-primary"
                        @click="enviarRespuestaConfirmador()"
                      >
                        Enviar respuesta
                      </button>
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>
          </div>

          <!-- Mapa -->

          <div
            class="modal-body"
            v-if="$store.getters.can('hidrocarburos.faltantes.mapa')"
          >
            <fieldset class="well-card rounded border border-primary">
              <legend
                class="well-legend-rounded text-bold bg-frontera text-light text-center small-text"
              >
                Mapa
                <button
                  @click="toggleCollapse6"
                  class="btn-toggle small-button"
                >
                  {{ isCollapsed6 ? "+" : "-" }}
                </button>
              </legend>
              <div class="tab-pane" v-show="!isCollapsed6">
                <div class="card-body">
                  <div>
                    <FaltantesMapa ref="FaltantesMapa" />
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import FaltantesMapa from "./FaltantesMapa";
export default {
  name: "FaltantesEscanerDocumento",
  components: {
    FaltantesMapa,
    vSelect,
  },
  data() {
    return {
      isCollapsed: false,
      isCollapsed2: false,
      isCollapsed3: false,
      isCollapsed4: false,
      isCollapsed5: false,
      isCollapsed6: false,
      isCollapsed7: false,
      isCollapsed8: false,
      isCollapsed9: false,
      isCollapsed10: false,

      selectedFileName: "",
      progress: null,
      file: null,
      tipoSoporte: "1",
      descripcion: "",
      CampoTextoSeguridad: "",
      CampoTextoMedicion: "",
      CampoTextoTransporte: "",
      CampoTextoConfirmador: "",
      diferencia_guia_notificacione_cobro_id: null,
      documentos: [],
      draftDocuments: [],
      loading: false,
      estadoNotificacion: null,
      estadoSeguridad: null,
      estadoMedicion: null,
      estadoTransporte: null,
      confirmador: null,
      estadoConfirmador: null,
      datos: [],
      areaSeleccionada: "",
      funcionarioSeleccionada: "",
      uri_docs: "http://localhost:8000/",
      /* uri_docs: process.env.VUE_APP_AWS_BUCKET_URL, */
      listasForms: {
        areas: [],
        estados: [
          { numeracion: 2, descripcion: "COBRO" },
          { numeracion: 3, descripcion: "NO COBRO" },
        ],
      },
      det_mapa: [],
      det_ruta: [],
    };
  },

  computed: {
    estadoDeshabilitado() {
      return this.estadoNotificacion === 3;
    },
    allDocuments() {
      return [
        ...this.draftDocuments.map((doc, index) => ({
          ...doc,
          key: "draft" + index,
        })),
        ...this.documentos.map((doc, index) => ({
          ...doc,
          key: "uploaded" + index,
        })),
      ];
    },
  },

  methods: {
    // Informacion general
    toggleCollapse() {
      this.isCollapsed = !this.isCollapsed;
    },
    // Cargue Documentos
    toggleCollapse2() {
      this.isCollapsed2 = !this.isCollapsed2;
    },
    // Vista Documentos
    toggleCollapse3() {
      this.isCollapsed3 = !this.isCollapsed3;
    },
    // Area
    toggleCollapse4() {
      this.isCollapsed4 = !this.isCollapsed4;
    },
    // Justificacion
    toggleCollapse5() {
      this.isCollapsed5 = !this.isCollapsed5;
    },
    toggleCollapse7() {
      this.isCollapsed7 = !this.isCollapsed7;
    },
    toggleCollapse8() {
      this.isCollapsed8 = !this.isCollapsed8;
    },
    // Mapa
    toggleCollapse6() {
      this.isCollapsed6 = !this.isCollapsed6;
    },
    // Confirmador
    toggleCollapse9() {
      this.isCollapsed9 = !this.isCollapsed9;
    },
    toggleCollapse10() {
      this.isCollapsed10 = !this.isCollapsed10;
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    //Listas
    getAreas() {
      axios.get("/api/lista/207").then((response) => {
        this.listasForms.areas = response.data;
      });
    },

    handleFileChange(event) {
      this.file = event.target.files[0];
      this.selectedFileName = this.file.name;
    },
    addDraftDocument() {
      if (!this.file) {
        this.$swal({
          icon: "error",
          title: "Por favor selecciona un documento",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        return;
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        this.draftDocuments.push({
          file: this.file,
          tipo_soporte: this.tipoSoporte,
          descripcion: this.descripcion,
          estado: "Borrador",
          preview: e.target.result,
        });
        this.file = null;
        this.tipoSoporte = "1";
        this.descripcion = "";
        this.selectedFileName = "";
        this.$refs.fileInput.value = null;
      };
      reader.readAsDataURL(this.file);
    },
    removeDraftDocument(index) {
      this.draftDocuments.splice(index, 1);
    },
    saveAllDocuments() {
      var vm = this;
      vm.loading = true;

      let formData = new FormData();
      this.draftDocuments.forEach((documento, index) => {
        formData.append(`documentos[${index}][documento]`, documento.file);
        formData.append(
          `documentos[${index}][tipo_soporte]`,
          documento.tipo_soporte
        );
        formData.append(
          `documentos[${index}][descripcion]`,
          documento.descripcion
        );
        formData.append(`documentos[${index}][estado]`, "Pendiente");
        formData.append(
          `documentos[${index}][diferencia_guia_notificacione_cobro_id]`,
          this.diferencia_guia_notificacione_cobro_id
        );
      });

      axios
        .post("/api/hidrocarburos/subir_documentos", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            vm.progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
          },
        })
        .then((response) => {
          this.documentos = this.documentos.concat(response.data);
          this.draftDocuments = [];
          this.$swal({
            icon: "success",
            title: "Todos los documentos se han subido exitosamente",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          vm.loading = false;
          /*           this.$parent.getIndex();
          this.$refs.closeModal.click(); */
        })

        .catch((error) => {
          console.error(error);
          this.$swal({
            icon: "error",
            title: "Hubo un error al subir los documentos",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          vm.loading = false;
        });
    },
    downloadDocumento(documento) {
      const documentUrl = `${this.uri_docs}${documento.file_path ||
        documento.link}`;
      if (documentUrl) {
        window.open(documentUrl, "_blank");
      } else {
        console.error("El documento no tiene un link definido.");
        this.$swal({
          icon: "error",
          title: "No se pudo descargar el documento",
          text: "El documento no tiene un link definido.",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    },
    tipoSoporteTexto(tipo) {
      const numTipo = Number(tipo);
      switch (numTipo) {
        case 1:
          return "ANALISIS GPS";
        case 2:
          return "REPORTE GPS";
        case 3:
          return "FORMATO R-TC-TT-054";
        case 4:
          return "OTRO";
        default:
          return "";
      }
    },
    estadoTexto(estado) {
      switch (estado) {
        case "Borrador":
          return "Borrador";
        case "Pendiente":
          return "Pendiente";
        case 1:
          return "Gestionado";
        case 2:
          return "Verificado";
        default:
          return "";
      }
    },
    llenar_modal_documento(faltante) {
      /*       this.$parent.cargando = true;
       */ this.diferencia_guia_notificacione_cobro_id = faltante.id;
      axios
        .get("/api/hidrocarburos/obtener_documentos/" + faltante.id)
        .then((response) => {
          if (Array.isArray(response.data)) {
            this.documentos = response.data.map((documento) => {
              return {
                ...documento,
                estado:
                  documento.estado === 1
                    ? "Gestionado"
                    : documento.estado === 2
                    ? "Verificado"
                    : "Pendiente",
                file_path: documento.link || "",
              };
            });
            this.datos = faltante;
            this.estadoNotificacion = faltante.estado;
            if (this.$store.getters.can("admin.casosMesaAyuda.edit")) {
              this.getDatosMapa();
            }
          }
          /*           this.$parent.cargando = false;|
           */
        })
        .catch((error) => {
          console.error(error);
          /*           this.$parent.cargando = false;
           */
        });
    },
    removeUploadedDocument(documento) {
      const documentoId = documento.id;
      axios
        .delete("/api/hidrocarburos/eliminar_documento/" + documento.id)
        .then((response) => {
          this.documentos = this.documentos.filter(
            (doc) => doc.id !== documentoId
          );
          this.$swal({
            title: "Documento eliminado",
            text: "El documento ha sido eliminado correctamente.",
            icon: "success",
            timer: 3000,
            timerProgressBar: true,
          });
        })
        .catch((error) => {
          console.error(error);
          this.$swal({
            title: "Error",
            text: "Hubo un error al eliminar el documento.",
            icon: "error",
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },
    enviarJustificacion() {
      this.contadorEnvios++;

      axios
        .put(
          "/api/hidrocarburos/faltantes/actualizar_estado/" +
            this.diferencia_guia_notificacione_cobro_id
        )
        .then((response) => {
          this.$swal({
            title: "Justificación Enviada",
            text: "La justificación ha sido enviada correctamente.",
            icon: "success",
            timer: 3000,
            timerProgressBar: true,
          });
          this.$parent.getIndex();
          this.$refs.closeModal.click();
        })
        .catch((error) => {
          console.error(error);
          this.$swal({
            title: "Error",
            text: "Hubo un error al enviar la justificación.",
            icon: "error",
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },
    enviarAreaEncargada() {
      const data = {
        diferencia_guia_notificacione_cobro_id: this
          .diferencia_guia_notificacione_cobro_id,
        nuevoEstado: 4,
        area: this.areaSeleccionada.map((area) => area.numeracion),
        intento: this.datos.cantidad_envios,
      };

      axios
        .put(
          `/api/hidrocarburos/faltantes/area_encargada/${this.diferencia_guia_notificacione_cobro_id}`,
          data
        )
        .then((response) => {
          this.estadoNotificacion = 4;
          this.$swal({
            title: "Justificación Enviada",
            text: "La justificación ha sido enviada correctamente.",
            icon: "success",
            timer: 3000,
            timerProgressBar: true,
          });
          this.$parent.getIndex();
          this.$refs.closeModal.click();
        })
        .catch((error) => {
          console.error(error);
          this.$swal({
            title: "Error",
            text: "Hubo un error al enviar la justificación.",
            icon: "error",
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    enviarAdminContrato() {
      const data = {
        diferencia_guia_notificacione_cobro_id: this
          .diferencia_guia_notificacione_cobro_id,
        nuevoEstado: 9,
        funcionario: this.funcionarioSeleccionada,
        intento: this.datos.cantidad_envios,
      };

      axios
        .put(
          `/api/hidrocarburos/faltantes/envio_admin/${this.diferencia_guia_notificacione_cobro_id}`,
          data
        )
        .then((response) => {
          this.estadoNotificacion = 4;
          this.$swal({
            title: "Justificación Enviada",
            text: "La justificación ha sido enviada correctamente.",
            icon: "success",
            timer: 3000,
            timerProgressBar: true,
          });
          this.$parent.getIndex();
          this.$refs.closeModal.click();
        })
        .catch((error) => {
          console.error(error);
          this.$swal({
            title: "Error",
            text: "Hubo un error al enviar la justificación.",
            icon: "error",
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    clearDraftDocuments() {
      this.draftDocuments = [];

      this.$refs.FaltantesMapa.novedades = [];
      this.$refs.FaltantesMapa.puntosTraza = [];
      this.$refs.FaltantesMapa.markersAd = [];
      this.$refs.FaltantesMapa.form.novedades = false;
      this.$refs.FaltantesMapa.infoContent = "";
      this.$refs.FaltantesMapa.infoWindowPos = {};
      this.$refs.FaltantesMapa.center;
      this.$refs.FaltantesMapa.markersEm = [];
      this.$refs.FaltantesMapa.markersIns = [];
      this.$refs.FaltantesMapa.markersGui = [];
      this.$refs.FaltantesMapa.markersGuides = [];

      this.CampoTextoMedicion = "";
      this.CampoTextoSeguridad = "";
      this.CampoTextoTransporte = "";
      this.CampoTextoConfirmador = "";
      this.estadoMedicion = null;
      this.estadoSeguridad = null;
      this.estadoTransporte = null;
      this.estadoConfirmador = null;
      this.det_mapa = [];
      this.det_ruta = [];

      this.isCollapsed6 = true;
      this.$refs.closeModal.click();
      this.$parent.getIndex();
    },
    getDatosMapa() {
      this.accion = 5;
      this.det_mapa = [];
      this.det_ruta = [];
      axios
        .get(
          "/api/hidrocarburos/ventanasEnturnamientoOperacion/ver_detalle_viaje",
          {
            params: {
              viaje_id: this.datos.viaje_id,
              accion: this.accion,
            },
          }
        )
        .then((response) => {
          this.det_mapa = response.data.posiciones;
          this.det_ruta = response.data.ruta;
        });
    },

    enviarRespuestaSeguridad() {
      if (
        this.datos.diferencias_guia_areas &&
        this.datos.diferencias_guia_areas.length > 0
      ) {
        const areasFiltradas = this.datos.diferencias_guia_areas.filter(
          (areaObj) => areaObj.area === "1"
        );

        const areaObj = areasFiltradas.slice(-1)[0];

        if (areaObj) {
          let data = {
            estadoSeguridad: this.estadoSeguridad,
            id: areaObj.id,
            diferencia_guia_notificacione_cobro_id:
              areaObj.diferencia_guia_notificacione_cobro_id,
            user_id: this.$store.getters.getUser.id,
            textoSeguridad: this.CampoTextoSeguridad,
            area: areaObj.area,
          };

          axios({
            method: "POST",
            url: "/api/hidrocarburos/faltantes/actualizarAreaSeguridad",
            data: data,
          })
            .then((response) => {
              this.$swal({
                title: "Se ha enviado la respuesta",
                text: "La justificación ha sido enviada correctamente.",
                icon: "success",
                timer: 3000,
                timerProgressBar: true,
              });
              this.$parent.getIndex();
              this.clearDraftDocuments();
              this.$refs.closeModal.click();
            })
            .catch((error) => {
              console.error(error);
              this.$swal({
                title: "Error",
                text: "Hubo un error al enviar la respuesta.",
                icon: "error",
                timer: 3000,
                timerProgressBar: true,
              });
            });
        }
      }
    },

    enviarRespuestaMedicion() {
      if (
        this.datos.diferencias_guia_areas &&
        this.datos.diferencias_guia_areas.length > 0
      ) {
        const areasFiltradas = this.datos.diferencias_guia_areas.filter(
          (areaObj) => areaObj.area === "3"
        );

        const areaObj = areasFiltradas.slice(-1)[0];

        if (areaObj) {
          let data = {
            estadoMedicion: this.estadoMedicion,
            id: areaObj.id,
            diferencia_guia_notificacione_cobro_id:
              areaObj.diferencia_guia_notificacione_cobro_id,
            user_id: this.$store.getters.getUser.id,
            textoMedicion: this.CampoTextoMedicion,
            area: areaObj.area,
            intento: this.datos.cantidad_envios,
          };

          axios({
            method: "POST",
            url: "/api/hidrocarburos/faltantes/actualizarAreaMedicion",
            data: data,
          })
            .then((response) => {
              this.$swal({
                title: "Se ha enviado la respuesta",
                text: "La justificación ha sido enviada correctamente.",
                icon: "success",
                timer: 3000,
                timerProgressBar: true,
              });
              this.$parent.getIndex();
              this.clearDraftDocuments();
              this.$refs.closeModal.click();
            })
            .catch((error) => {
              console.error(error);
              this.$swal({
                title: "Error",
                text: "Hubo un error al enviar la respuesta.",
                icon: "error",
                timer: 3000,
                timerProgressBar: true,
              });
            });
        }
      }
    },

    enviarRespuestaTransporte() {
      if (
        this.datos.diferencias_guia_areas &&
        this.datos.diferencias_guia_areas.length > 0
      ) {
        // Filtrar las áreas que sean igual a "2"
        const areasFiltradas = this.datos.diferencias_guia_areas.filter(
          (areaObj) => areaObj.area === "2"
        );

        // Obtener el último elemento filtrado
        const areaObj = areasFiltradas.slice(-1)[0];

        if (areaObj) {
          // Asegúrate de que exista un objeto con area "2"
          let data = {
            estadoTransporte: this.estadoTransporte,
            id: areaObj.id,
            diferencia_guia_notificacione_cobro_id:
              areaObj.diferencia_guia_notificacione_cobro_id,
            user_id: this.$store.getters.getUser.id,
            textoTransporte: this.CampoTextoTransporte,
            area: areaObj.area,
            intento: this.datos.cantidad_envios,
          };
          axios({
            method: "POST",
            url: "/api/hidrocarburos/faltantes/actualizarAreaTransporte",
            data: data,
          })
            .then((response) => {
              this.$swal({
                title: "Se ha enviado la respuesta",
                text: "La justificación ha sido enviada correctamente.",
                icon: "success",
                timer: 3000,
                timerProgressBar: true,
              });
              this.$parent.getIndex();
              this.clearDraftDocuments();
              this.$refs.closeModal.click();
            })
            .catch((error) => {
              console.error(error);
              this.$swal({
                title: "Error",
                text: "Hubo un error al enviar la respuesta.",
                icon: "error",
                timer: 3000,
                timerProgressBar: true,
              });
            });
        } else {
          console.error("No se encontró un área con valor '2'.");
        }
      }
    },

    enviarRespuestaConfirmador() {
      if (this.datos.estado == 9) {
        console.log("DEbe entrar");

        try {
          const response = axios
            .post("/api/hidrocarburos/faltantes/envioCorreoDiferencias", {
              estadoConfirmador: this.estadoConfirmador,
              CampoTextoConfirmador: this.CampoTextoConfirmador,
              id: this.datos.id,
            })
            .then((response) => {
              this.$swal({
                icon: "success",
                title: "Respuesta enviada",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
              this.$parent.getIndex();
              this.clearDraftDocuments();
              this.$refs.closeModal.click();
            });
        } catch (error) {
          console.error("Error al enviar la respuesta:", error);
          alert("Hubo un error al enviar la respuesta");
        }
      } else {
        console.log("No debe");

        try {
          const response = axios
            .post("/api/hidrocarburos/faltantes/confirmador", {
              estadoConfirmador: this.estadoConfirmador,
              CampoTextoConfirmador: this.CampoTextoConfirmador,
              id: this.datos.id,
            })
            .then((response) => {
              this.$swal({
                icon: "success",
                title: "Respuesta enviada",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
              this.$parent.getIndex();
              this.clearDraftDocuments();
              this.$refs.closeModal.click();
            });
        } catch (error) {
          console.error("Error al enviar la respuesta:", error);
          alert("Hubo un error al enviar la respuesta");
        }
      }
    },
  },

  enviarCorreo() {
    axios
      .get("/api/hidrocarburos/postulaciones/enviarCorreo", {
        params: {
          solicitud_diaria_id: this.$route.params.solicitud_diaria_id,
          empresa_id: this.$route.params.empresa_id,
        },
      })
      .then((response) => {
        this.$swal({
          icon: "success",
          title: "Correo enviado satisfactoriamente",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      })
      .catch(function(error) {
        this.$swal({
          icon: "error",
          title: "Error, en el envio de correo:" + error,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      });
  },

  mounted() {
    this.getAreas();
  },
};
</script>
<style>
.well-legend-rounded {
  border-radius: 9px;
  padding: 3px;
  margin: 9px;
  max-width: 290px;
}
.btn-toggle {
  background: none;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
  color: white;
  margin-left: 10px;
}
.small-text {
  font-size: 1rem;
}
.small-button {
  font-size: 1em;
}
.form-control-file {
  padding: 0.5rem;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
</style>
